import React from "react";
import { mainLogo } from "./SmallComponents/Images";
import { ExampleButton } from "./SmallComponents/StyledWalletButton";
import { Box, useMediaQuery } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
export default function Header() {
  const matches = useMediaQuery("(max-width:992px)");
  return (
    <>
      {/* header-area */}
      <header id="header">
        <div id="sticky-header" className="menu-area transparent-header">
          <div className="container custom-container">
            <div className="row">
              <div className="col-12">
                <div className="menu-wrap">
                  <nav className="menu-nav">
                    <div className="logo">
                      <HashLink
                        smooth
                        to="/#home"
                        style={{ textDecoration: "none", margin: "auto" }}
                      >
                        <img src={mainLogo} width="80px" alt="Logo" />
                      </HashLink>
                    </div>
                    <div className="navbar-wrap main-menu d-none d-lg-flex">
                      <ul className="navigation">
                        <li className="active">
                          <HashLink
                            className="section-link"
                            smooth
                            to="/#home"
                            style={{ textDecoration: "none", margin: "auto" }}
                          >
                            About
                          </HashLink>
                        </li>
                        <li>
                          <Link
                            smooth
                            to="/staking"
                            style={{ textDecoration: "none", margin: "auto" }}
                          >
                            Staking
                          </Link>
                        </li>
                        <li>
                          <HashLink
                            className="section-link"
                            smooth
                            to="/#tokenomics"
                            style={{ textDecoration: "none", margin: "auto" }}
                          >
                            Tokenomics
                          </HashLink>
                        </li>
                        <li>
                          <HashLink
                            className="section-link"
                            smooth
                            to="/#roadMap"
                            style={{ textDecoration: "none", margin: "auto" }}
                          >
                            RoadMap
                          </HashLink>
                        </li>
                        <li>
                          <Link
                            smooth
                            to="/giveaway"
                            style={{ textDecoration: "none", margin: "auto" }}
                          >
                            Win $5m
                          </Link>
                        </li>
                        <li>
                          <HashLink
                            className="section-link"
                            smooth
                            to="/#faq"
                            style={{ textDecoration: "none", margin: "auto" }}
                          >
                            Faq
                          </HashLink>
                        </li>
                        <li>
                          <HashLink
                            className="section-link"
                            smooth
                            to="/#contact-us"
                            style={{ textDecoration: "none", margin: "auto" }}
                          >
                            Contact Us
                          </HashLink>
                        </li>
                      </ul>
                    </div>
                    <div className="header-action">
                      <ul className="list-wrap">
                        <li>
                          <HashLink className="header-login" to="/#home">
                            <a>Buy Now</a>
                          </HashLink>
                        </li>
                        <li className="offcanvas-menu">
                          <a href="#" className="menu-tigger">
                            <i className="fas fa-bars" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <Box display="flex" alignItems="center">
                      {matches && (
                        <HashLink to="/#home" style={{ marginRight: "10px" }}>
                          <Box
                            sx={{
                              color: "#131315",
                              background: "#68bcbb",
                              fontSize: "22px",
                              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                              textTransform: "capitalize",
                              fontFamily: "Outfit",
                              borderRadius: "30px",
                              fontWeight: "600",
                              px: 2,
                              py: 1,
                            }}
                          >
                            {" "}
                            Buy Now
                          </Box>
                        </HashLink>
                      )}

                      <div className="mobile-nav-toggler">
                        <i className="fas fa-bars" />
                      </div>
                    </Box>
                  </nav>
                </div>
                {/* Mobile Menu  */}
                <div className="mobile-menu">
                  <nav className="menu-box">
                    <div className="close-btn">
                      <i className="fas fa-times" />
                    </div>
                    <div className="nav-logo">
                      <a href="/">
                        <img src="assets/img/logo/logo.png" alt="Logo" />
                      </a>
                    </div>
                    <div className="menu-outer">
                      {/*Here Menu Will Come Automatically Via Javascript / Same Menu as in Header*/}
                    </div>
                    <div className="social-links">
                      <ul className="clearfix list-wrap">
                        <li>
                          <a
                            target="_blank"
                            href="https://www.facebook.com/groups/kaspanexus"
                          >
                            <i className="fab fa-facebook-f" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://twitter.com/kaspa_nexus"
                          >
                            <i className="fab fa-twitter" />
                          </a>
                        </li>
                        <li>
                          <a
                            target="_blank"
                            href="https://t.me/kaspanexusoffical"
                          >
                            <i className="fas fa-paper-plane" />
                          </a>
                        </li>
                        <li>
                          <ExampleButton />
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
                <div className="menu-backdrop" />
                {/* End Mobile Menu */}
              </div>
            </div>
          </div>
        </div>
        {/* offCanvas-menu */}
        <div className="extra-info">
          <div className="close-icon menu-close">
            <button>
              <i className="far fa-window-close" />
            </button>
          </div>
          <div className="logo-side mb-30">
            <a href="/">
              <img src="assets/img/logo/logo.png" alt="Logo" />
            </a>
          </div>
          <div className="side-info mb-30">
            {/* <div className="contact-list mb-30">
              <h4>Office Address</h4>
              <p>
                123/A, Miranda City Likaoli <br /> Prikano, Dope
              </p>
            </div>
            <div className="contact-list mb-30">
              <h4>Phone Number</h4>
              <p>+0989 7876 9865 9</p>
              <p>+(090) 8765 86543 85</p>
            </div> */}
            <div className="contact-list mb-30">
              <h4>Email Address</h4>
              <p>support@kaspanexus.com</p>
              {/* <p>example.mail@hum.com</p> */}
            </div>
          </div>
          <div className="social-icon-right mt-30">
            <a
              target="_blank"
              href="https://www.facebook.com/groups/kaspanexus"
            >
              <i className="fab fa-facebook-f" />
            </a>
            <a target="_blank" href="https://twitter.com/kaspa_nexus">
              <i className="fab fa-twitter" />
            </a>
            <a target="_blank" href="https://t.me/kaspanexusoffical">
              <i className="fas fa-paper-plane" />
            </a>
          </div>
          <br />
          <ExampleButton />
        </div>
        <div className="offcanvas-overly" />
        {/* offCanvas-menu-end */}
      </header>
      {/* header-area-end */}
    </>
  );
}
